import { UploadSample } from '@/components/gs-upload/sample-dialog'
import { Template } from '@/types'

export const demoVideoUploadSamples: UploadSample[] = [
  {
    name: 'Trump (2s)',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T03-45-56-6eb7b866.mp4',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-02-38-1f16674f.jpg',
  },
  {
    name: 'Trump (4s)',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-05-00-d2301915.mp4',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-03-20-e29adb03.jpg',
  },
  {
    name: 'Trump (8s)',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-05-15-3aa8d96f.mp4',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-03-34-cb12964b.jpg',
  },
  {
    name: 'Trump (12s)',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-05-28-ec7f4c03.mp4',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-03-52-e1a14fc7.jpg',
  },
  {
    name: 'Trump (16s)',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-14-52-5b2135e2.mp4',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-04-05-b501f344.jpg',
  },
]

export const faceImageUploadSamples: UploadSample[] = [
  {
    name: 'face 1',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-18-48-0bf8fe56.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-18-48-0bf8fe56.jpg',
  },
  {
    name: 'face 2',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-21-18-408b1731.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-21-18-408b1731.jpg',
  },
  {
    name: 'face 3',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-21-35-483d6723.jpeg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-21-35-483d6723.jpeg',
  },
  {
    name: 'face 4',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-21-52-5da4132c.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-21-52-5da4132c.jpg',
  },
  {
    name: 'face 5',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-22-20-f22d381c.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-22-20-f22d381c.jpg',
  },
  {
    name: 'face 6',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-22-35-250a655f.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-22-35-250a655f.jpg',
  },
  {
    name: 'face 7',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-22-50-c154dadf.jpeg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-22-50-c154dadf.jpeg',
  },
  {
    name: 'face 8',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-23-12-7ee40536.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-23-12-7ee40536.jpg',
  },
  {
    name: 'face 9',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-23-31-52f57d4f.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-23-31-52f57d4f.jpg',
  },
  {
    name: 'face 10',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-30-50-50953ffd.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-30-50-50953ffd.jpg',
  },
  {
    name: 'face 11',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-31-19-805fbded.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-31-19-805fbded.jpg',
  },
  {
    name: 'face 12',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-31-36-8a7aca1b.png',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-31-36-8a7aca1b.png',
  },
  {
    name: 'face 13',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-32-07-9b409793.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-32-07-9b409793.jpg',
  },
  {
    name: 'face 14',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-33-07-3904f9c8.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-33-07-3904f9c8.jpg',
  },
  {
    name: 'face 15',
    url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-35-18-f2420380.jpg',
    thumbnail_url: 'https://cdnvb5.haiper.ai/u/65a7e49727941e6b8368d979/2024-09-14T04-35-18-f2420380.jpg',
  },
]

export const demoTemplates: Template[] = [
  {
    name: 'Smart phone with dramatic lighting',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/Smart-phone-with-dramatic-lighting.png',
    category: 'product',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/Smart-phone-with-dramatic-lighting.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/Smart-phone-with-dramatic-lighting.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:1XqbEp3-gmVqNVJMmh-A3',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Laptop in a modern office space',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/Laptop-in-a-modern-office-space.png',
    category: 'product',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/Laptop-in-a-modern-office-space.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/Laptop-in-a-modern-office-space.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:kCUVEW2aEG7SHhVkiNlRl',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Skincare bottles on silk with warm lighting',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/Amber-Skincare-Bottles-on-Silk.PNG',
    category: 'product',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/Amber-Skincare-Bottles-on-Silk.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/Amber-Skincare-Bottles-on-Silk.PNG',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:-4Eu2QBV0l8wCCdU6-Ven',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Bottle of perfume in a garden of flowers',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/Bottle-of-perfume-in-a-garden-of-flowers.png',
    category: 'product',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/Bottle-of-perfume-in-a-garden-of-flowers.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/Bottle-of-perfume-in-a-garden-of-flowers.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:GWD3uC8jU2T7q1j9Iv8Jr',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Hamburger on a flaming grill',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/Hamburger-on-a-flaming-grill.png',
    category: 'product',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/Hamburger-on-a-flaming-grill.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/Hamburger-on-a-flaming-grill.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:9-u63bSCm4Ut7FP_BuJJ4',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Water in front of a serene waterfall',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/Water-in-front-of-a-serene-waterfall.png',
    category: 'product',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/Water-in-front-of-a-serene-waterfall.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/Water-in-front-of-a-serene-waterfall.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:uF1mqVmhA1FaJqENR5niR',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Sofa sitting on top of fluffy white clouds',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/Sofa-sitting-on-top-of-fluffy-white-clouds.png',
    category: 'product',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/Sofa-sitting-on-top-of-fluffy-white-clouds.mp4',
        thumbnail_url:
          'https://scontent.haiper.ai/webapp/templates/cover/Sofa-sitting-on-top-of-fluffy-white-clouds.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:l271PxXK_ndcw7lhTud7Q',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Chest of drawers in a modern luxury home',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/Chest-of-drawers-in-a-modern-luxury-home.png',
    category: 'product',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/Chest-of-drawers-in-a-modern-luxury-home.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/Chest-of-drawers-in-a-modern-luxury-home.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:Z4ZyeqVWw4kGesqKr3Zuy',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: '2D to 3D',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/2d-3d.jpg',
    category: 'style_transfer',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/2d-3d.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/2d-3d.jpg',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:-dWTWNU3sk9HLONmKj5zLC',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Realism to 3D Clay',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/3d-clay.jpg',
    category: 'style_transfer',
    tags: [],
    examples: [
      {
        media_url: '',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/3d-clay.jpg',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:RE8fMy-OViGYQEryzYNbw',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Ocean Wave Logo',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/logo-wave.jpg',
    category: 'logo',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/logo-wave.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/logo-wave.jpg',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:ZobEN1_kFw6IRmkDmTlKY',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Logo-neon',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/logo-neon.jpg',
    category: 'logo',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/logo-neon.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/logo-neon.jpg',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:Jd3sHYHRtd_eEjoP3G7hp',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'logo-water-form',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/logo-water-form.jpg',
    category: 'logo',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/logo-water-form.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/logo-water-form.jpg',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:X32CVO6I-J1FuOPqyOoPm',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Realism to Sketch',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: '',
    category: 'style_transfer',
    tags: [],
    examples: [
      {
        media_url: '',
        thumbnail_url: '',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:_LB6QJPoOykb5lomh3soK',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Subject 3',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/66ea2270735eaf552370ff72.png',
    category: 'dance',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/66ea2270735eaf552370ff72.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/66ea2270735eaf552370ff72.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:p1Mo3IhacAImnLfau8z-F',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Trump dance',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/66ea25e0a23b26a04ebce06b.png',
    category: 'dance',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/66ea25e0a23b26a04ebce06b.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/66ea25e0a23b26a04ebce06b.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:LSG7gz93Iqb4nXuDateQF',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Dance 1',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/202409172343.png',
    category: 'dance',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/202409172343.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/202409172343.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:lPTaQRiFW3rylIKTDzlm7',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Dance 2',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/66ea1e63735eaf552370ff6d.png',
    category: 'dance',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/66ea1e63735eaf552370ff6d.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/66ea1e63735eaf552370ff6d.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:yR06MGayINjha68YMoEpL',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
  {
    name: 'Dance 3',
    author_id: '66d71b856de2e2c44f287250',
    create_time: '2024-09-16T15:45:56.261Z',
    update_time: '2024-09-16T15:45:56.261Z',
    version: 1,
    cover_image: 'https://scontent.haiper.ai/webapp/templates/cover/66ea12eca23b26a04ebce045.png',
    category: 'dance',
    tags: [],
    examples: [
      {
        media_url: 'https://scontent.haiper.ai/webapp/templates/cover/66ea12eca23b26a04ebce045.mp4',
        thumbnail_url: 'https://scontent.haiper.ai/webapp/templates/cover/66ea12eca23b26a04ebce045.png',
      },
    ],
    styles: [],
    is_public: true,
    introduction: '',
    template_id: 'demo:taD-SVxW9UdAd2dGgqmyP',
    author_name: 'Haiper Creative Studio',
    author_avatar:
      'https://cdnvb4.haiper.ai/_u/66d71b856de2e2c44f287250/NQcBGmaY7Bik27Y1TT6ZF/9eea5438-85c7-42f2-864b-796c1e86b23e.png',
  },
]
